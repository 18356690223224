const allDictionaries: Record<string, Record<string, string>> = {
  ru: {
    Join: 'Подключиться',
    'Marketplace of the Future': 'Маркетплейс будущего',
    'ARMMP marketplace provides an opportunity to purchase various services of partner companies rapidly and without commission.':
      'Маркетплейс АРММП дает возможность оперативно и без комиссии для конечного покупателя приобрести различные услуги компаний партнёров.',
    Advantage: 'Преимущества',
    'Absolute transparency of the transaction':
      'Абсолютная прозрачность сделки',
    'Clear interface': 'Понятный интерфейс',
    'A wide range of services': 'Широкий спектр услуг',
    'Payment by any payment system (Visa, MasterCard)':
      'Оплата любой платёжной системой (Visa, MasterCard)',
    'Industry leaders as our partners': 'Среди партнеров лидеры индустрий',
    'How can service sellers connect?': 'Как подключиться продавцам услуг?',
    'Leave a request and we will send you clear instructions for connecting.':
      'Оставьте заявку, и мы вам пришлём понятную инструкцию для подключения.',
    'Send request': 'Оставить заявку',
    'Our partners': 'Наши партнёры',
    'Please leave information about yourself': 'Оставьте информацию о себе',
    'Our team will contact you shortly.':
      'Наша команда свяжется с вами в ближайшее время.',
    'Company name': 'Название компании',
    'Company site': 'Сайт компании',
    'First & Last names*': 'Имя, Фамилия*',
    'E-mail*': 'E-mail*',
    'Phone number': 'Телефон',
    Send: 'Отправить',
    'By submitting information through an electronic form, you consent to the processing of personal data.':
      'Отправляя сведения через электронную форму, Вы даёте согласие на обработку персональных данных.',
    'All rights reserved.': 'Все права защищены.',
    'Thanks!': 'Спасибо!',
  },
  en: {
    Join: 'Join',
    'Marketplace of the Future': 'Marketplace of the Future',
    'ARMMP marketplace provides an opportunity to purchase various services of partner companies rapidly and without commission.':
      'ARMMP marketplace provides an opportunity to purchase various services of partner companies rapidly and without commission.',
    Advantage: 'Advantage',
    'Absolute transparency of the transaction':
      'Absolute transparency of the transaction',
    'Clear interface': 'Clear interface',
    'A wide range of services': 'A wide range of services',
    'Payment by any payment system (Visa, MasterCard)':
      'Payment by any payment system (Visa, MasterCard)',
    'Industry leaders as our partners': 'Industry leaders as our partners',
    'How can service sellers connect?': 'How can service sellers connect?',
    'Leave a request and we will send you clear instructions for connecting.':
      'Leave a request and we will send you clear instructions for connecting.',
    'Send request': 'Send request',
    'Our partners': 'Our partners',
    'Please leave information about yourself':
      'Please leave information about yourself',
    'Our team will contact you shortly.': 'Our team will contact you shortly.',

    'Company name': 'Company name',
    'Company site': 'Company site',
    'First & Last names*': 'First & Last names*',
    'E-mail*': 'E-mail*',
    'Phone number': 'Phone number',
    Send: 'Send',
    'By submitting information through an electronic form, you consent to the processing of personal data.':
      'By submitting information through an electronic form, you consent to the processing of personal data.',
    'All rights reserved.': 'All rights reserved.',

    'Thanks!': 'Thanks!',
  },
  ar: {
    Join: 'Միանալ',
    'Marketplace of the Future': 'Ապագայի մարկետփլեյս',
    'ARMMP marketplace provides an opportunity to purchase various services of partner companies rapidly and without commission.':
      'ARMMP մարկետփլեյսը հնարավորություն է տալիս արագ և առանց միջնորդավճարի ձեռք բերել գործընկերների տարբեր ծառայություններ:',
    Advantage: 'Առավելությունները.',
    'Absolute transparency of the transaction':
      'գործարքի բացարձակ թափանցիկություն',
    'Clear interface': 'հասկանալի ինտերֆեյս',
    'A wide range of services': 'ծառայությունների լայն շրջանակ',
    'Payment by any payment system (Visa, MasterCard)':
      'վճարում ցանկացած վճարային համակարգով (Visa, MasterCard)',
    'Industry leaders as our partners':
      'ոլորտների առաջատարները գործընկերների շրջանում',
    'How can service sellers connect?':
      'Ինչպես միանալ ծառայության վաճառողներին?',
    'Leave a request and we will send you clear instructions for connecting.':
      'Թողեք դիմումը, և մենք ձեզ միանալու հստակ հրահանգը կուղարկենք:',
    'Send request': 'Միանալ',
    'Our partners': 'Մեր գործընկերները',
    'Please leave information about yourself':
      'Տեղեկություններ թողեք ձեր մասին',
    'Our team will contact you shortly.': 'Մեր թիմը շուտով կկապվի ձեզ հետ',
    'Company name': 'Ընկերության Անվանումը',
    'Company site': 'Ընկերության կայքը',
    'First & Last names*': 'Անուն ազգանուն',
    'E-mail*': 'E-mail*',
    'Phone number': 'Հեռախոսահամար',
    Send: 'Ուղարկել',
    'By submitting information through an electronic form, you consent to the processing of personal data.':
      'Էլեկտրոնային ձևի միջոցով տեղեկատվություն ներկայացնելով՝ դուք համաձայնում եք անձնական տվյալների մշակմանը.',
    'All rights reserved.': 'Բոլոր իրավունքները պաշտպանված են',
    'Thanks!': 'Շնորհակալություն!',
  },
};

export function useTranslate(lang: string): (lang: string) => string {
  const dict = allDictionaries[lang];

  function _t(text: string): string {
    return dict[text];
  }

  return _t;
}
