import type { ReactNode } from 'react';
import React from 'react';

import { HtmlHead } from 'components/layouts/HtmlHead';

interface Props {
  children: ReactNode;
  fullHeight?: boolean;
  fullWidth?: boolean;
  withoutHeader?: boolean;
  withoutFooter?: boolean;
  className?: string;
  grid?: boolean;
  removeBottomPadding?: boolean;
  seo?: {
    title?: string;
    description?: string;
  };
}

export default function Page({ children, seo }: Props): JSX.Element {
  return (
    <>
      <HtmlHead {...seo} />
      <main>{children}</main>
    </>
  );
}
