import Button from '@a3/chameleon/src/components/basic/Button';
import InputText from '@a3/chameleon/src/components/basic/InputText';
import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import {
  BodyM,
  Caption1,
  H1,
  H2,
} from '@a3/chameleon/src/components/basic/Typography';
import cn from 'classnames';
import React, { useState } from 'react';
import type { FC, FormEvent } from 'react';

import { defaultTitle } from 'components/layouts/HtmlHead/constants';
import Page from 'components/layouts/Page';
import { useTranslate } from 'components/pagesComponents/MainLandingPage/constants';

import s from './MainLandingPage.module.scss';

interface Props {
  lang: 'ru' | 'ar' | 'en';
}

const arrow = (
  <svg
    width="7"
    height="14"
    viewBox="0 0 7 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.85355 6.64645C7.04882 6.84171 7.04882 7.15829 6.85355 7.35355L0.853554 13.3536C0.658292 13.5488 0.34171 13.5488 0.146448 13.3536C-0.0488146 13.1583 -0.0488146 12.8417 0.146448 12.6464L5.79289 7L0.146448 1.35355C-0.0488148 1.15829 -0.0488148 0.841709 0.146448 0.646447C0.34171 0.451185 0.658292 0.451185 0.853554 0.646447L6.85355 6.64645Z"
      fill="#1D1D1D"
    />
  </svg>
);
const langMenu = {
  ru: (
    <>
      Rus
      {arrow}
      <div className={s.allLanguages}>
        <a href="/">Eng</a>
        <a href="/ar/">Arm</a>
      </div>
    </>
  ),
  en: (
    <>
      Eng
      {arrow}
      <div className={s.allLanguages}>
        <a href="/ru/">Rus</a>
        <a href="/ar/">Arm</a>
      </div>
    </>
  ),
  ar: (
    <>
      Arm
      {arrow}
      <div className={s.allLanguages}>
        <a href="/ru/">Rus</a>
        <a href="/">Eng</a>
      </div>
    </>
  ),
};

const MainLandingPage: FC<Props> = ({ lang }) => {
  const t = useTranslate(lang);
  const [formIsSent, setFormIsSent] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [url, setUrl] = useState('');
  const [phone, setPhone] = useState('');

  function scrollToForm(): void {
    document.querySelector('.js-form')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center',
    });
  }

  const submitForm = async (evt: FormEvent): Promise<void> => {
    evt.preventDefault();

    const response = await fetch(
      'https://andromeda.a-3.ru/api/armmp-form-request/',
      {
        method: 'POST',
        body: JSON.stringify({
          name: name,
          email: email,
          phone: phone,
          companyName: companyName,
          url: url,
          serviceName: 'ARMMP',
        }),
      },
    );

    if (response.status === 200) {
      setFormIsSent(true);
    } else {
      console.error('response ->>', response);
    }
  };

  const title = lang === 'ru' ? 'Маркетплейс будущего' : defaultTitle;

  return (
    <Page seo={{ title: title }}>
      <div className={s.bgWrapper}>
        <div className={s.bg} />
      </div>
      <Typograf>
        <div className={s.header}>
          <div className={s.container}>
            <img src="/img/logo.svg" className="main-logo" alt="logo" />
            <div className={s.headerRightWrapper}>
              <Button
                className={cn(s.onlyDesktop, 'js-scroll-to-form')}
                size="medium"
                onClick={scrollToForm}
              >
                {t('Join')}
              </Button>
              <div className={s.languageMenu}>{langMenu[lang]}</div>
            </div>
          </div>
        </div>

        <div className={s.hero}>
          <div className={s.container}>
            <H1 className={s.h1}>{t('Marketplace of the Future')}</H1>
            <BodyM>
              {t(
                'ARMMP marketplace provides an opportunity to purchase various services of partner companies rapidly and without commission.',
              )}
            </BodyM>
            <Button
              className={cn(s.onlyMobile, s.connectButton)}
              onClick={scrollToForm}
            >
              {t('Join')}
            </Button>
          </div>
        </div>

        <div className={s.contentBg}>
          <div className={s.container}>
            <H2 className={s.h2}>{t('Advantage')}</H2>

            <div className={s.contentItemContainer}>
              <div className={cn(s.contentItem, s.areaA)}>
                {t('Absolute transparency of the transaction')}
              </div>

              <div className={cn(s.contentItem, s.areaB)}>
                {t('Clear interface')}
              </div>

              <div className={cn(s.contentItem, s.areaC)}>
                {t('A wide range of services')}
              </div>

              <div className={cn(s.contentItem, s.areaD)}>
                {t('Payment by any payment system (Visa, MasterCard)')}
              </div>
              <div className={cn(s.contentItem, s.areaE)}>
                {t('Industry leaders as our partners')}
              </div>
            </div>
          </div>

          <div className={s.connectWrapper}>
            <div className={s.container}>
              <H2 className={s.h2}>{t('How can service sellers connect?')}</H2>
              <BodyM>
                {t(
                  'Leave a request and we will send you clear instructions for connecting.',
                )}
              </BodyM>
              <Button className={s.button} size="large" onClick={scrollToForm}>
                {t('Send request')}
              </Button>
            </div>
          </div>

          {/*<H2 className={cn(s.textCenter, s.h2)}>{t('Our partners')}</H2>*/}

          {/*<div className={s.logos} />*/}
        </div>

        <div className={cn(s.formWrapper, 'js-form ')}>
          {formIsSent ? (
            <div className={cn(s.container, s.thanks)}>
              <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M22.9886 -0.000244141C22.4024 -0.000244141 21.8712 0.339792 21.6332 0.867419L12.4657 21.1857H6.26253C4.60161 21.1857 3.00871 21.8357 1.83425 22.9926C0.6598 24.1495 0 25.7185 0 27.3546V43.8321C0 45.4682 0.659799 47.0372 1.83425 48.1941C3.00871 49.351 4.60161 50.001 6.26253 50.001H13.4315C13.4471 50.001 13.4627 50.0007 13.4782 50.0003H40.3774C41.8845 50.0149 43.3466 49.4937 44.4952 48.532C45.6459 47.5685 46.4045 46.2275 46.631 44.7566L49.9287 23.5715C50.0649 22.6874 50.0044 21.7846 49.7513 20.9258C49.4981 20.067 49.0585 19.2727 48.4628 18.5979C47.8671 17.9231 47.1296 17.3839 46.3013 17.0177C45.4758 16.6528 44.5798 16.4684 43.6751 16.4772H31.6407V8.52257C31.6407 6.26218 30.7292 4.09437 29.1066 2.49603C27.484 0.897693 25.2833 -0.000244141 22.9886 -0.000244141ZM14.9147 47.0781H40.3852L40.402 47.0782C41.1969 47.0871 41.9682 46.8127 42.5739 46.3055C43.1796 45.7984 43.5789 45.0927 43.6981 44.3185L46.9958 23.133C47.0674 22.6679 47.0356 22.1923 46.9024 21.7405C46.7692 21.2885 46.5378 20.8705 46.2243 20.5153C45.9108 20.1601 45.5226 19.8764 45.0867 19.6837C44.6507 19.4909 44.1775 19.3939 43.6997 19.3992L43.6829 19.3993H30.1575C29.3383 19.3993 28.6743 18.7452 28.6743 17.9382V8.52257C28.6743 7.03717 28.0753 5.61261 27.009 4.56227C26.1644 3.73036 25.0816 3.18719 23.9185 2.99725L14.9147 22.9527V47.0781Z"
                  fill="#002BFF"
                />
              </svg>
              <h3>{t('Thanks!')}</h3>
              <p>{t('Our team will contact you shortly.')}</p>
            </div>
          ) : (
            <div className={cn(s.container, s.formContainer)}>
              <H2>{t('Please leave information about yourself')}</H2>
              <BodyM>{t('Our team will contact you shortly.')}</BodyM>
              <form
                className={cn(s.form, 'js-form-content')}
                onSubmit={submitForm}
              >
                <InputText
                  name="companyName"
                  type="text"
                  className={cn(s.areaA)}
                  placeholder={t('Company name')}
                  value={companyName}
                  onChange={(newValue): void => {
                    setCompanyName(newValue);
                  }}
                />
                <InputText
                  name="url"
                  type="text"
                  className={cn(s.areaB)}
                  placeholder={t('Company site')}
                  value={url}
                  onChange={(newValue): void => {
                    setUrl(newValue);
                  }}
                />

                <InputText
                  name="name"
                  type="text"
                  className={cn(s.areaC)}
                  placeholder={t('First & Last names*')}
                  value={name}
                  onChange={(newValue): void => {
                    setName(newValue);
                  }}
                  required
                />

                <InputText
                  name="email"
                  className={cn(s.areaD)}
                  placeholder={t('E-mail*')}
                  required
                  value={email}
                  onChange={(newValue): void => {
                    setEmail(newValue);
                  }}
                />

                <InputText
                  name="phone"
                  type="text"
                  className={cn(s.areaE)}
                  placeholder={t('Phone number')}
                  value={phone}
                  onChange={(newValue): void => {
                    setPhone(newValue);
                  }}
                />

                <Button
                  className={cn(s.button, s.areaF)}
                  size="large"
                  type="submit"
                >
                  {t('Send')}
                </Button>
                <Caption1 className={s.areaG} Tag="p">
                  {t(
                    'By submitting information through an electronic form, you consent to the processing of personal data.',
                  )}
                </Caption1>
              </form>
            </div>
          )}
        </div>

        <div className={s.footer}>
          <div className={s.container}>© 2022. {t('All rights reserved.')}</div>
        </div>
      </Typograf>
    </Page>
  );
};

export default MainLandingPage;
