export const defaultTitle = 'Marketplace of the future';
export const defaultDescription = '';
export const defaultOgDescription = '';

export const jsonLd = {
  '@context': 'http://schema.org',
  '@type': 'Corporation',
  name: '',
  description: '',
  logo: '',
  url: '',
  email: 'mailto:',
  sameAs: [],
};
